import React from "react"

const DumbellIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon_gym-01 2">
        <path
          id="Vector"
          d="M8.25889 12.7485C8.13825 12.7488 8.01937 12.7196 7.9126 12.6634V14.2606H16.0871V12.6634C15.9805 12.7195 15.8617 12.7487 15.7412 12.7485H8.25889ZM7.9126 9.7395V11.337C8.01937 11.2808 8.13825 11.2516 8.25889 11.2519H15.7412C15.8617 11.2517 15.9805 11.2809 16.0871 11.337V9.7395L7.9126 9.7395Z"
          fill="black"
        />
        <path
          id="Vector_2"
          d="M6.56429 2.90332H6.5555C6.15375 2.90327 5.75592 2.98236 5.38473 3.13606C5.01354 3.28976 4.67626 3.51507 4.39214 3.79912C4.10803 4.08316 3.88264 4.42039 3.72886 4.79155C3.57507 5.1627 3.49589 5.56051 3.49585 5.96226V18.0377C3.49589 18.4395 3.57507 18.8373 3.72886 19.2084C3.88264 19.5796 4.10803 19.9168 4.39214 20.2009C4.67626 20.4849 5.01354 20.7102 5.38473 20.8639C5.75592 21.0176 6.15375 21.0967 6.5555 21.0967H6.56429C6.96602 21.0967 7.36383 21.0176 7.735 20.8639C8.10616 20.7102 8.44342 20.4849 8.7275 20.2008C9.01159 19.9168 9.23694 19.5796 9.39069 19.2084C9.54444 18.8373 9.62358 18.4395 9.62358 18.0377V5.96226C9.62358 5.56053 9.54444 5.16273 9.39069 4.79158C9.23694 4.42043 9.01159 4.0832 8.7275 3.79915C8.44342 3.5151 8.10616 3.28979 7.735 3.13608C7.36383 2.98237 6.96602 2.90328 6.56429 2.90332ZM7.91253 17.5628C7.91253 17.7404 7.87754 17.9163 7.80955 18.0805C7.74157 18.2446 7.64192 18.3937 7.5163 18.5194C7.39068 18.645 7.24155 18.7446 7.07742 18.8126C6.91328 18.8806 6.73737 18.9156 6.55972 18.9156C6.38206 18.9156 6.20615 18.8806 6.04202 18.8126C5.87789 18.7446 5.72875 18.645 5.60313 18.5194C5.47751 18.3937 5.37787 18.2446 5.30988 18.0805C5.2419 17.9163 5.2069 17.7404 5.2069 17.5628V6.43723C5.2069 6.25957 5.2419 6.08366 5.30988 5.91953C5.37787 5.7554 5.47751 5.60626 5.60313 5.48064C5.72875 5.35502 5.87789 5.25538 6.04202 5.18739C6.20615 5.11941 6.38206 5.08441 6.55972 5.08441C6.73737 5.08441 6.91328 5.11941 7.07742 5.18739C7.24155 5.25538 7.39068 5.35502 7.5163 5.48064C7.64192 5.60626 7.74157 5.7554 7.80955 5.91953C7.87754 6.08366 7.91253 6.25957 7.91253 6.43723V17.5628Z"
          fill="black"
        />
        <path
          id="Vector_3"
          d="M2.76971 4.96265H2.61683C1.97039 4.96265 1.35044 5.21944 0.893338 5.67654C0.436239 6.13364 0.179444 6.7536 0.179443 7.40003V16.5999C0.179443 16.92 0.242488 17.2369 0.364978 17.5326C0.487468 17.8283 0.667005 18.097 0.893337 18.3234C1.11967 18.5497 1.38836 18.7292 1.68408 18.8517C1.9798 18.9742 2.29675 19.0373 2.61683 19.0373H2.76971C3.08979 19.0373 3.40674 18.9742 3.70246 18.8517C3.99818 18.7292 4.26687 18.5497 4.4932 18.3234C4.71954 18.097 4.89907 17.8284 5.02156 17.5326C5.14405 17.2369 5.2071 16.92 5.2071 16.5999V7.40003C5.2071 6.7536 4.9503 6.13364 4.4932 5.67654C4.0361 5.21944 3.41614 4.96265 2.76971 4.96265ZM3.52526 16.1602C3.52526 16.2695 3.50374 16.3777 3.46193 16.4786C3.42012 16.5795 3.35884 16.6713 3.28158 16.7485C3.20432 16.8258 3.1126 16.8871 3.01166 16.9289C2.91072 16.9707 2.80253 16.9922 2.69327 16.9922C2.58401 16.9922 2.47582 16.9707 2.37488 16.9289C2.27394 16.8871 2.18222 16.8258 2.10496 16.7485C2.0277 16.6713 1.96642 16.5795 1.92461 16.4786C1.8828 16.3777 1.86128 16.2695 1.86128 16.1602V7.83971C1.86128 7.73046 1.8828 7.62227 1.92461 7.52132C1.96642 7.42038 2.0277 7.32867 2.10496 7.25141C2.18222 7.17415 2.27394 7.11287 2.37488 7.07105C2.47582 7.02924 2.58401 7.00772 2.69327 7.00772C2.80253 7.00772 2.91072 7.02924 3.01166 7.07105C3.1126 7.11287 3.20432 7.17415 3.28158 7.25141C3.35884 7.32866 3.42012 7.42038 3.46193 7.52132C3.50374 7.62227 3.52526 7.73045 3.52526 7.83971V16.1602Z"
          fill="black"
        />
        <path
          id="Vector_4"
          d="M17.4446 2.90332H17.4358C17.034 2.90328 16.6362 2.98237 16.2651 3.13608C15.8939 3.28979 15.5566 3.5151 15.2725 3.79915C14.9885 4.0832 14.7631 4.42043 14.6094 4.79158C14.4556 5.16273 14.3765 5.56053 14.3765 5.96226V18.0377C14.3765 18.4395 14.4556 18.8373 14.6094 19.2084C14.7631 19.5796 14.9885 19.9168 15.2725 20.2008C15.5566 20.4849 15.8939 20.7102 16.2651 20.8639C16.6362 21.0176 17.034 21.0967 17.4358 21.0967H17.4446C17.8463 21.0967 18.2441 21.0176 18.6153 20.8639C18.9865 20.7102 19.3238 20.4849 19.6079 20.2009C19.892 19.9168 20.1174 19.5796 20.2712 19.2084C20.425 18.8373 20.5042 18.4395 20.5042 18.0377V5.96226C20.5042 5.56051 20.425 5.1627 20.2712 4.79155C20.1174 4.42039 19.892 4.08316 19.6079 3.79912C19.3238 3.51507 18.9865 3.28976 18.6153 3.13606C18.2441 2.98236 17.8463 2.90327 17.4446 2.90332ZM18.7928 17.5628C18.7928 17.9216 18.6503 18.2657 18.3966 18.5194C18.1429 18.7731 17.7988 18.9156 17.44 18.9156C17.0812 18.9156 16.7371 18.7731 16.4834 18.5194C16.2297 18.2657 16.0872 17.9216 16.0872 17.5628V6.43723C16.0872 6.07844 16.2297 5.73435 16.4834 5.48064C16.7371 5.22694 17.0812 5.08441 17.44 5.08441C17.7988 5.08441 18.1429 5.22694 18.3966 5.48064C18.6503 5.73435 18.7928 6.07844 18.7928 6.43723V17.5628Z"
          fill="black"
        />
        <path
          id="Vector_5"
          d="M21.2304 4.96265H21.3832C22.0297 4.96265 22.6496 5.21944 23.1067 5.67654C23.5638 6.13364 23.8206 6.7536 23.8206 7.40003V16.5999C23.8206 16.92 23.7576 17.2369 23.6351 17.5326C23.5126 17.8283 23.3331 18.097 23.1067 18.3234C22.8804 18.5497 22.6117 18.7292 22.316 18.8517C22.0203 18.9742 21.7033 19.0373 21.3832 19.0373H21.2304C20.9103 19.0373 20.5933 18.9742 20.2976 18.8517C20.0019 18.7292 19.7332 18.5497 19.5069 18.3234C19.2805 18.097 19.101 17.8284 18.9785 17.5326C18.856 17.2369 18.793 16.92 18.793 16.5999V7.40003C18.793 6.7536 19.0498 6.13364 19.5069 5.67654C19.964 5.21944 20.5839 4.96265 21.2304 4.96265ZM20.4748 16.1602C20.4748 16.2695 20.4963 16.3777 20.5381 16.4786C20.5799 16.5795 20.6412 16.6713 20.7185 16.7485C20.7957 16.8258 20.8875 16.8871 20.9884 16.9289C21.0893 16.9707 21.1975 16.9922 21.3068 16.9922C21.4161 16.9922 21.5242 16.9707 21.6252 16.9289C21.7261 16.8871 21.8178 16.8258 21.8951 16.7485C21.9724 16.6713 22.0336 16.5795 22.0755 16.4786C22.1173 16.3777 22.1388 16.2695 22.1388 16.1602V7.83971C22.1388 7.73046 22.1173 7.62227 22.0755 7.52132C22.0336 7.42038 21.9724 7.32867 21.8951 7.25141C21.8178 7.17415 21.7261 7.11287 21.6252 7.07105C21.5242 7.02924 21.4161 7.00772 21.3068 7.00772C21.1975 7.00772 21.0893 7.02924 20.9884 7.07105C20.8875 7.11287 20.7957 7.17415 20.7185 7.25141C20.6412 7.32867 20.5799 7.42038 20.5381 7.52132C20.4963 7.62227 20.4748 7.73045 20.4748 7.83971V16.1602Z"
          fill="black"
        />
      </g>
    </svg>
  )
}

export default DumbellIcon
