import React from "react"

const LocationIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon_location (1) 1" clip-path="url(#clip0_87_29)">
        <g id="Clip path group">
          <mask
            id="mask0_87_29"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <g id="clip0_823_15">
              <path id="Vector" d="M24 0H0V24H24V0Z" fill="white" />
            </g>
          </mask>
          <g mask="url(#mask0_87_29)">
            <g id="Group">
              <path
                id="Vector_2"
                d="M11.958 24.008L11.2609 23.4105C10.2999 22.6058 1.90894 15.3591 1.90894 10.0583C1.90894 4.50839 6.40804 0.00927734 11.958 0.00927734C17.5079 0.00927734 22.007 4.50839 22.007 10.0583C22.007 15.3592 13.616 22.6059 12.659 23.4145L11.958 24.008ZM11.958 2.18217C7.61015 2.18709 4.08679 5.71045 4.08187 10.0582C4.08187 13.3883 9.24431 18.7081 11.958 21.1429C14.6716 18.7071 19.834 13.3843 19.834 10.0582C19.8291 5.71045 16.3058 2.18714 11.958 2.18217Z"
                fill="black"
              />
              <path
                id="Vector_3"
                d="M11.958 14.0416C9.75802 14.0416 7.97461 12.2582 7.97461 10.0583C7.97461 7.85836 9.75802 6.07495 11.958 6.07495C14.1579 6.07495 15.9413 7.85836 15.9413 10.0583C15.9413 12.2582 14.1579 14.0416 11.958 14.0416ZM11.958 8.06658C10.858 8.06658 9.96628 8.95828 9.96628 10.0582C9.96628 11.1582 10.858 12.0499 11.958 12.0499C13.0579 12.0499 13.9496 11.1582 13.9496 10.0582C13.9496 8.95828 13.058 8.06658 11.958 8.06658Z"
                fill="black"
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_87_29">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LocationIcon
