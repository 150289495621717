import React, { createElement } from "react"
import TimetableIcon from "./CalendarClockIcon"
import LocationIcon from "./LocationIcon"
import PersonIcon from "./PersonIcon"
import MobileIcon from "./MobileIcon"
import DumbellIcon from "./DumbellIcon"
import EmploeesIcon from "./EmploeesIcon"
import DealIcon from "./DealIcon"
import TimeIcon from "./TimeIcon"
import EuroCoinIcon from "./CoinIcon"
import SportBenefitIcon from "./SportBenefitIcon"
import ClockIcon from "./ClockIcon"

const Icons = {
  euro: EuroCoinIcon,
  access: TimetableIcon,
  location: LocationIcon,
  person: PersonIcon,
  persons: EmploeesIcon,
  mobile: MobileIcon,
  dumbbell: DumbellIcon,
  handshake: DealIcon,
  time: TimeIcon,
  SportBenefit: SportBenefitIcon,
  clock: ClockIcon,
}

const IconsRenderComponent = ({ iconsAmount = 1, icon }) => {
  const elements = []

  for (let i = 0; i < iconsAmount; i++) {

    if (Icons[icon]) {
      const element = createElement(Icons[icon])
      elements.push(element)
    }
  }

  return elements
}

export default IconsRenderComponent
