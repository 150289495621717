import React from "react"
import "./CompetitiveEdgeTable.scss"
import { register } from "swiper/element/bundle"
import IconsRenderComponent from "./Icons/IconsRenderComponent"

register()

const tableData = {
  headers: {
    brand: "SportBenefit",
    externalGyms: "External gyms",
    reimbursement: "Reimbursement",
    officeGym: "Office gym",
  },
  body: {
    totalCost: {
      title: "Total cost",
      brand: "€",
      externalGyms: "€",
      reimbursement: "€-€€€",
      officeGym: "€-€€€",
      titleIcon: "euro",
    },
    access: {
      title: "Access",
      brand: "Unlimited",
      externalGyms: "Possible restrictions",
      reimbursement: "Unlimited",
      officeGym: "Limited hours",
      titleIcon: "access",
    },
    coverage: {
      title: "Coverage & location",
      brand: 4,
      externalGyms: 1,
      reimbursement: 1,
      officeGym: 1,
      titleIcon: "location",
      icon: "location",
    },
    activities: {
      title: "In-person experience",
      brand: "45+ various activities",
      externalGyms: "Gym only",
      reimbursement: "Full service gyms and studios",
      officeGym: "Gym only",
      titleIcon: "person",
    },
    digitalSolution: {
      title: "Digital solution",
      brand: "Yes",
      externalGyms: "No",
      reimbursement: "No",
      officeGym: "No",
      titleIcon: "mobile",
    },
    referGym: {
      title: "Refer a gym",
      brand: "Yes",
      externalGyms: "No",
      reimbursement: "No",
      officeGym: "No",
      titleIcon: "dumbbell",
    },
    extendToFamilyMember: {
      title: "Extend to family member",
      brand: "Yes",
      externalGyms: "No",
      reimbursement: "No",
      officeGym: "No",
      titleIcon: "persons",
    },
    referralProgram: {
      title: "Referral program for HR's",
      brand: "Yes",
      externalGyms: "No",
      reimbursement: "No",
      officeGym: "No",
      titleIcon: "handshake",
    },
    adminWorkload: {
      title: "Admin workload",
      brand: 1,
      externalGyms: 3,
      reimbursement: 3,
      officeGym: 4,
      titleIcon: "time",
      icon: "clock",
    },
  },
}

const CompetitiveEdgeTable = ({ data }) => {
  const { headers, body } = tableData

  const headersKeys = Object.keys(headers)
  const comparativeFeatures = Object.keys(body)

  return (
    <section className="competitive-edge">
      <h3>Explore our Competitive Edge in the Wellbeing Landscape</h3>
      <p>Discover the Exclusive Edge of SportBenefit: Your Sole Corporate Wellbeing Solution with Access to 155+ Gyms, 45+ Activities, and Cyprus' Premier Wellbeing App</p>
      
      <div className="competitive-edge-table">
        <div className="competitive-edge-table-head">
          <table>
            <tr>
              <th></th>
              {headersKeys.map((headerKey, headerKeyIndex) => (
                <th key={`competitive-edge-table-header-${headerKeyIndex}`}>
                  {headerKey === "brand" ? (
                    <IconsRenderComponent icon={headers[headerKey]} />
                  ) : (
                    headers[headerKey]
                  )}
                </th>
              ))}
            </tr>
          </table>
        </div>

        <div className="competitive-edge-table-body">
          <table>
            {comparativeFeatures.map((comparativeFeature, featureIndex) => (
              <tr key={`competitive-edge-table-row-${featureIndex}`}>
                <th>
                  <div className="competitive-edge-table-body_header-content">
                    {body[comparativeFeature].titleIcon && (
                      <IconsRenderComponent
                        icon={body[comparativeFeature].titleIcon}
                      />
                    )}
                    <span>{body[comparativeFeature].title}</span>
                  </div>
                </th>

                {headersKeys.map((headerKey, headerKeyIndex) => (
                  <td key={`competitive-edge-table-cell-${headerKeyIndex}`}>
                    {typeof body[comparativeFeature][headerKey] === "number" ? (
                      <IconsRenderComponent
                        icon={body[comparativeFeature].icon}
                        iconsAmount={body[comparativeFeature][headerKey]}
                      />
                    ) : (
                      body[comparativeFeature][headerKey]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </table>
        </div>
      </div>

      <div className="competitive-edge-carousel">
        <swiper-container loop={true} slides-per-view="1" pagination="true">
          {comparativeFeatures.map((comparativeFeature, featureIndex) => (
            <swiper-slide key={`carousel-slide-${featureIndex}`}>
              <div className="competitive-edge-carousel-item">
                <div className="competitive-edge-carousel-item_header">
                  <IconsRenderComponent
                    icon={body[comparativeFeature].titleIcon}
                  />
                  <h4>{body[comparativeFeature].title}</h4>
                </div>
                <div className="competitive-edge-carousel-item_body">
                  <table>
                    {headersKeys.map((headerKey, headerKeyIndex) => {
                      return headerKey === "brand" ? (
                        <tr key={`competitive-edge-carousel-slide-table-row-${headerKeyIndex}`}>
                          <th>
                            <IconsRenderComponent icon={headers[headerKey]} />
                          </th>
                          <td>
                            {typeof body[comparativeFeature][headerKey] ===
                            "number" ? (
                              <IconsRenderComponent
                                icon={body[comparativeFeature].icon}
                                iconsAmount={
                                  body[comparativeFeature][headerKey]
                                }
                              />
                            ) : (
                              body[comparativeFeature][headerKey]
                            )}
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <th>{headers[headerKey]}</th>
                          <td>
                            {typeof body[comparativeFeature][headerKey] ===
                            "number" ? (
                              <IconsRenderComponent
                                icon={body[comparativeFeature].icon}
                                iconsAmount={
                                  body[comparativeFeature][headerKey]
                                }
                              />
                            ) : (
                              body[comparativeFeature][headerKey]
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </table>
                </div>
              </div>
            </swiper-slide>
          ))}
        </swiper-container>
      </div>
    </section>
  )
}

export default CompetitiveEdgeTable
