import React, { useState, useRef, useEffect } from "react"
import "../index.scss"

import Layout from "src/components/layout"
import Head from "src/components/Head"

import SportObjectsSearch from "src/components/clients/SportObjectsSearch/SportObjectsSearch"
import Start from "src/components/Start/Start"
import Top from "src/components/Top/Top"
import Top2 from "src/components/Top2/Top2"
import Description from "src/components/companies/Description/Description"
import Benefits from "src/components/companies/Benefits/Benefits"
import Testimonials from "src/components/partners/Testimonials/Testimonials"
import { clientSportBenefitTestimonials } from "src/components/clients/testimonials"
import YoutubeVideo, {
  YOUTUBE_VIDEO_LINKS,
} from "src/components/YoutubeVideo/YoutubeVideo"

import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "react-i18next"
import COUNTRY from "../../constants"
import BRANDS from "../../brands"
import CompetitiveEdgeTable from "../../components/companies/CompetitiveEdge/CompetitiveEdgeTable"

const ClientsMain = ({ location }) => {
  const data = useStaticQuery(graphql`
    query mainPageClientsCyEn {
      site {
        siteMetadata {
          senderPath
          countries {
            cy {
              hotLine {
                tel
                title
                telephone
                telephoneTitle
              }
              statistics {
                supplier {
                  subscription_amount_array {
                    key
                    value
                  }
                }
                activity {
                  subscription_amount_array {
                    key
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  let vip_amount = ""
  data.site.siteMetadata.countries.cy.statistics.supplier.subscription_amount_array.map(
    ({ key, value }) => {
      if (key === "platinum") {
        vip_amount = value
      }
    }
  )

  let vip_activities_amount = ""
  data.site.siteMetadata.countries.cy.statistics.activity.subscription_amount_array.map(
    ({ key, value }) => {
      if (key === "platinum") {
        vip_activities_amount = value
      }
    }
  )

  const { t, i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage(COUNTRY.cy_en)
  }, [i18n])

  const [email, setEmail] = useState("")
  const [emailDisabled, setEmailDisabled] = useState(false)
  const [form, setForm] = useState({
    company: "",
  })
  const sendForm = (form) => {
    setForm(form)
    return fetch(data.site.siteMetadata.senderPath, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    })
      .then((response) => {
        alert(t("sendFormAlert"))
      })
      .catch((e) => {
        console.error(e)
      })
  }
  const transferEmail = (email) => {
    setForm(Object.assign(form, { email }))
    setEmailDisabled(true)
  }

  const startRef = useRef()
  return (
    <div className="main-page">
      <Head
        lang="cy_en"
        title={t("head.index.title", { brand: BRANDS.SPORTBENEFIT.brand })}
        description={t("head.description", {
          brand: BRANDS.SPORTBENEFIT.brand,
        })}
        slug="/"
      />
      <Layout
        hotLine={data.site.siteMetadata.countries.cy.hotLine}
        location={location}
        country="cy_en"
      >
        <Top2
          type="clients"
          startRef={startRef}
          email={email}
          setEmail={setEmail}
          transferEmail={transferEmail}
          platinum_amount={vip_amount}
          platinum_activities_amount={vip_activities_amount}
          country="cy_en"
        />
        <Testimonials
          title={"Feedback from our clients"}
          baseTestimonials={clientSportBenefitTestimonials}
        />
        <SportObjectsSearch country="cy" />
        <Top
          type="companies"
          startRef={startRef}
          email={email}
          setEmail={setEmail}
          transferEmail={transferEmail}
          platinum_amount={vip_amount}
          country="cy_en"
        />
        <YoutubeVideo videoSrc={YOUTUBE_VIDEO_LINKS.cy_en} />
        <Description
          platinum_amount={vip_amount}
          platinum_activities_amount={vip_activities_amount}
        />
        <CompetitiveEdgeTable />
        <Benefits />
        <Start
          type="companies"
          startRef={startRef}
          email={email}
          emailDisabled={emailDisabled}
          setEmail={setEmail}
          setForm={sendForm}
        />
      </Layout>
    </div>
  )
}

export default ClientsMain
