import React from "react"

const ClockIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_18_2755)">
        <path
          d="M12.5 24C5.883 24 0.5 18.617 0.5 12C0.5 5.383 5.883 0 12.5 0C19.117 0 24.5 5.383 24.5 12C24.5 18.617 19.117 24 12.5 24ZM12.5 2C6.986 2 2.5 6.486 2.5 12C2.5 17.514 6.986 22 12.5 22C18.014 22 22.5 17.514 22.5 12C22.5 6.486 18.014 2 12.5 2ZM17.5 12C17.5 11.447 17.053 11 16.5 11H13.5V6C13.5 5.447 13.052 5 12.5 5C11.948 5 11.5 5.447 11.5 6V12C11.5 12.553 11.948 13 12.5 13H16.5C17.053 13 17.5 12.553 17.5 12Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_18_2755">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ClockIcon
