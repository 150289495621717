import React from "react"

const TimeIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="free-icon-font-time-fast-3914080 1"
        clip-path="url(#clip0_18_2747)"
      >
        <path
          id="Vector"
          d="M9 24H1C0.734784 24 0.48043 23.8946 0.292893 23.7071C0.105357 23.5196 0 23.2652 0 23C0 22.7348 0.105357 22.4804 0.292893 22.2929C0.48043 22.1054 0.734784 22 1 22H9C9.26522 22 9.51957 22.1054 9.70711 22.2929C9.89464 22.4804 10 22.7348 10 23C10 23.2652 9.89464 23.5196 9.70711 23.7071C9.51957 23.8946 9.26522 24 9 24Z"
          fill="black"
        />
        <path
          id="Vector_2"
          d="M7 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19C0 18.7348 0.105357 18.4804 0.292893 18.2929C0.48043 18.1054 0.734784 18 1 18H7C7.26522 18 7.51957 18.1054 7.70711 18.2929C7.89464 18.4804 8 18.7348 8 19C8 19.2652 7.89464 19.5196 7.70711 19.7071C7.51957 19.8946 7.26522 20 7 20Z"
          fill="black"
        />
        <path
          id="Vector_3"
          d="M5 16H1C0.734784 16 0.48043 15.8946 0.292893 15.7071C0.105357 15.5196 0 15.2652 0 15C0 14.7348 0.105357 14.4804 0.292893 14.2929C0.48043 14.1054 0.734784 14 1 14H5C5.26522 14 5.51957 14.1054 5.70711 14.2929C5.89464 14.4804 6 14.7348 6 15C6 15.2652 5.89464 15.5196 5.70711 15.7071C5.51957 15.8946 5.26522 16 5 16Z"
          fill="black"
        />
        <path
          id="Vector_4"
          d="M13 23.955C12.7348 23.9668 12.4757 23.8728 12.2798 23.6936C12.084 23.5144 11.9673 23.2647 11.9555 22.9995C11.9437 22.7343 12.0377 22.4752 12.2169 22.2794C12.3961 22.0835 12.6458 21.9668 12.911 21.955C14.8034 21.7818 16.6074 21.073 18.1115 19.9116C19.6157 18.7501 20.7577 17.1841 21.4039 15.397C22.0501 13.6099 22.1737 11.6756 21.7602 9.8208C21.3468 7.96597 20.4133 6.26735 19.0692 4.9239C17.7252 3.58044 16.0261 2.64779 14.1711 2.23516C12.3161 1.82254 10.3819 1.94703 8.59505 2.59405C6.80823 3.24108 5.24276 4.38385 4.08201 5.88852C2.92125 7.39319 2.21327 9.19745 2.04098 11.09C2.01711 11.3541 1.88929 11.598 1.68562 11.7679C1.48196 11.9378 1.21914 12.0199 0.954983 11.996C0.690827 11.9721 0.446973 11.8443 0.277065 11.6406C0.107157 11.437 0.0251132 11.1741 0.0489827 10.91C0.327872 7.8333 1.78259 4.98269 4.11031 2.95156C6.43804 0.920423 9.45942 -0.134743 12.5455 0.00570469C15.6316 0.146152 18.5447 1.47139 20.6783 3.70556C22.8119 5.93973 24.0017 8.91069 24 12C24.0153 14.9983 22.9014 17.8926 20.8799 20.107C18.8583 22.3214 16.0773 23.6938 13.09 23.951C13.06 23.954 13.029 23.955 13 23.955Z"
          fill="black"
        />
        <path
          id="Vector_5"
          d="M12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V12C11.0001 12.2652 11.1055 12.5195 11.293 12.707L14.293 15.707C14.4816 15.8892 14.7342 15.99 14.9964 15.9877C15.2586 15.9854 15.5094 15.8802 15.6948 15.6948C15.8802 15.5094 15.9854 15.2586 15.9877 14.9964C15.99 14.7342 15.8892 14.4816 15.707 14.293L13 11.586V7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_18_2747">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TimeIcon
