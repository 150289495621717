import React from "react"

const MobileIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="free-icon-font-mobile-notch-8034760 1"
        clip-path="url(#clip0_87_803)"
      >
        <path
          id="Vector"
          d="M15.045 1.75782e-05C15.018 -0.000982422 8.982 -0.000982422 8.955 1.75782e-05C6.219 0.0240176 4 2.25802 4 4.99902V18.999C4 21.756 6.243 23.999 9 23.999H15C17.757 23.999 20 21.756 20 18.999V5.00002C20 2.25802 17.781 0.0250176 15.045 1.75782e-05ZM18 18.999C18 20.653 16.654 21.999 15 21.999H9C7.346 21.999 6 20.653 6 18.999V5.00002C6 3.54702 7.038 2.33302 8.411 2.05802L9.105 3.44702C9.274 3.78602 9.621 4.00002 10 4.00002H14C14.379 4.00002 14.725 3.78602 14.895 3.44702L15.589 2.05802C16.962 2.33202 18 3.54702 18 5.00002V18.999ZM13 19.999H11C10.448 19.999 10 19.551 10 18.999C10 18.447 10.448 17.999 11 17.999H13C13.552 17.999 14 18.447 14 18.999C14 19.551 13.552 19.999 13 19.999Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_87_803">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MobileIcon
